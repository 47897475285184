import SectionTitle from '../../components/Common/SectionTitle'

import techImg1 from '../../assets/img/technology/style2/1.png'
import techImg2 from '../../assets/img/technology/style2/azure.png'
// import techImg3 from '../../assets/img/technology/style2/3.png'
// import techImg4 from '../../assets/img/technology/style2/4.png'
// import techImg5 from '../../assets/img/technology/style2/5.png'
import techImgDocker from '../../assets/img/technology/style2/docker.png'
import techImg7 from '../../assets/img/technology/style2/7.png'
import techImg8 from '../../assets/img/technology/style2/8.png'
import techImgK8s from '../../assets/img/technology/style2/k8s.png'
import techImg10 from '../../assets/img/technology/style2/10.png'
import techImg11 from '../../assets/img/technology/style2/11.png'
import techImg12 from '../../assets/img/technology/style2/12.png'
import techImgTf from '../../assets/img/technology/style2/tf.png'
import techImg14 from '../../assets/img/technology/style2/14.png'
import techImg15 from '../../assets/img/technology/style2/15.png'
import techImg16 from '../../assets/img/technology/style2/16.png'
import techImgAnsible from '../../assets/img/technology/style2/ansible.png'
import techImgProm from '../../assets/img/technology/style2/prom.png'
import techImgKeycloak from '../../assets/img/technology/style2/keycloak.png'


const Technology = (props) => {
  return (
    <div className="acznowy-technology acznowy-rain-animate  gray-bg  pt-120 pb-130 md-pt-70 md-pb-80 black-dark">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-44"
          subtitleClass="sub-text gold-color"
          subtitle="Technology Index"
          titleClass="title title2 white-color"
          title="What Technology We Are Using For Our Valued Customers"
        />
        <div className="row white-bg round-border">

          <div className="col-4">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg2} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImgK8s} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImgTf} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-2">
            <div className="technology-item ">
              <a href="#">
                <div className="logo-img ">
                  <img src={techImg1} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImgDocker} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImgKeycloak} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImgAnsible} alt="" />
                </div>
              </a>
            </div>
          </div>





          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImgProm} alt="" />
                </div>
              </a>
            </div>
          </div>


          {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg9} alt="" />
                </div>
              </a>
            </div>
          </div> */}
          {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg10} alt="" />
                </div>
              </a>
            </div>
          </div> */}

          {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg13} alt="" />
                </div>
              </a>
            </div>
          </div> */}
          {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg14} alt="" />
                </div>
              </a>
            </div>
          </div> */}
          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg8} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg15} alt="" />
                </div>
              </a>
            </div>
          </div>


          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg11} alt="" />
                </div>
              </a>
            </div>
          </div>

          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg12} alt="" />
                </div>
              </a>
            </div>
          </div>


          <div className="col-3">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg7} alt="" />
                </div>
              </a>
            </div>
          </div>
          {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg16} alt="" />
                </div>
              </a>
            </div>
          </div> */}
          {/* <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg17} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="technology-item">
              <a href="#">
                <div className="logo-img">
                  <img src={techImg18} alt="" />
                </div>
              </a>
            </div>
          </div> */}
        </div>
        <div className="col-lg-12 text-center">
          <div className="btn-part mt-30">
            <a className="readon started" href="#">
              Get Started
            </a>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Technology
