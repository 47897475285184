import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/img/logo/logo.png';

const TopHeader = () => {

    return (
        <div className="topbar-area hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2">
                        <div className="logo-part"> // TODO LOGO
                            {/* <Link to="/"><img src={Logo} alt="Logo" /></Link> */}
                        </div>
                    </div>
                    <div className="col-lg-10 text-right">
                        <ul className="acznowy-contact-info">
                            {/* <li className="contact-part">
                                <i className="flaticon-location"></i>
                                <span className="contact-info">
                                    <span>Address</span>
                                    Smolna 1D, 80-877 Sopot, Poland
                                </span>
                            </li> */}
                            <li className="contact-part">
                                <i className="flaticon-email"></i>
                                <span className="contact-info">
                                    <span>E-mail</span>
                                    <a href="mailto:chat@acznowy.pl"> chat@acznowy.pl</a>
                                </span>
                            </li>
                            <li className="contact-part">
                                <i className="flaticon-call"></i>
                                <span className="contact-info">
                                    <span>Phone</span>
                                    (+48) 600 - 500 - 429
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;