import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';



//Custom Components

import HomeElevenOnePage from '../pages/onepage-11';
import About from '../pages/about';
import Team from '../pages/team';
import TeamDetails from '../pages/team/team-details';
import Blog from '../pages/blog';
import BlogDetails from '../pages/blog/blog-details';
import Services from '../pages/service';
import ServiceOne from '../pages/service/service-1';
import ServiceTwo from '../pages/service/service-2';
import ServiceThree from '../pages/service/service-3';
import ServiceDetails from '../pages/service/service-details';
import SoftwareDevelopment from '../pages/service/software-development';
import WebDevelopment from '../pages/service/web-development';
import AnalyticSolutions from '../pages/service/analytic-solutions';
import cloudAndDevops from '../pages/service/cloud-and-devops';
import ProjectDesign from '../pages/service/project-design';
import DataCenter from '../pages/service/data-center';
import CaseStudtyStyleOne from '../pages/case-study/style-1';
import CaseStudtyStyleTwo from '../pages/case-study/style-2';
import CaseStudtyStyleThree from '../pages/case-study/style-3';
import CaseStudtyStyleFour from '../pages/case-study/style-4';
import CaseStudtyStyleFive from '../pages/case-study/style-5';
import CaseStudtyStyleSix from '../pages/case-study/style-6';
import CaseStudtyStyleSeven from '../pages/case-study/style-7';
import Shop from '../pages/shop';
import ShopSingle from '../pages/shop/shop-single';
import Cart from '../pages/shop/cart';
import Checkout from '../pages/shop/checkout';
import MyAccount from '../pages/shop/my-account';
import Faq from '../pages/faq';
import Pricing from '../pages/pricing';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={HomeElevenOnePage} />
                    <Route path="/home" component={HomeElevenOnePage} />
                    {/* <Route path="/about" component={About} /> */}
                    <Route path="/about" component={Error} />
                    <Route path="/service" exact component={Services} />
                    {/* <Route path="/service/service-1" component={ServiceOne} />
                    <Route path="/service/service-2" component={ServiceTwo} />
                    <Route path="/service/service-3" component={ServiceThree} />
                    <Route path="/service/service-details" component={ServiceDetails} />
                    <Route path="/service/software-development" component={SoftwareDevelopment} />
                    <Route path="/service/web-development" component={WebDevelopment} />
                    <Route path="/service/analytic-solutions" component={AnalyticSolutions} />
                    <Route path="/service/cloud-and-devops" component={cloudAndDevops} />
                    <Route path="/service/project-design" component={ProjectDesign} />
                    <Route path="/service/data-center" component={DataCenter} /> */}
                    {/* <Route path="/team" exact component={Team} /> */}
                    {/* <Route path="/team/team-details" component={TeamDetails} /> */}
                    {/* <Route path="/blog" exact component={Blog} />
                    <Route path="/blog/blog-details" component={BlogDetails} />
                    <Route path="/case-study/style-1" component={CaseStudtyStyleOne} />
                    <Route path="/case-study/style-2" component={CaseStudtyStyleTwo} />
                    <Route path="/case-study/style-3" component={CaseStudtyStyleThree} />
                    <Route path="/case-study/style-4" component={CaseStudtyStyleFour} />
                    <Route path="/case-study/style-5" component={CaseStudtyStyleFive} />
                    <Route path="/case-study/style-6" component={CaseStudtyStyleSix} />
                    <Route path="/case-study/style-7" component={CaseStudtyStyleSeven} /> */}
                    <Route path="/faq" component={Faq} />
                    {/* <Route path="/pricing" component={Pricing} /> */}
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
