import SectionTitle from '../../components/Common/SectionTitle'
import AppointmentForm from '../../components/Appointment/Appointment'

// WhyChooseUs Images
import iconOne from '../../assets/img/choose/icons/style2/1.png'
import iconTwo from '../../assets/img/choose/icons/style2/2.png'
import iconThree from '../../assets/img/choose/icons/style2/3.png'

const WhyChooseUs2 = () => {
  return (
    <div       id="acznowy-why-choose-us2" className="acznowy-why-choose-us2 style2 modify1 acznowy-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 md-mb-30">
            <SectionTitle
              sectionClass="sec-title mb-40"
              subtitleClass="sub-text dark-color style4-bg left"
              subtitle="Get in Touch"
              titleClass="title dark-color pb-20"
              title="Consult with Skilled Acznowy Engineers"
              descClass="desc dark-color fixed-width"
              description="Discover the full range of Acznowy's software development and IT consulting services. Our expert engineers are ready to help you find the perfect solution for your business's digital transformation and growth."
            />
            <div className="services-wrap mb-24">
              <div className="services-icon">
                <img src={iconOne} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title dark-color">
                  <a href="#">Email</a>
                </h3>
                <p className="services-txt dark-color">chat@acznowy.pl</p>
              </div>
            </div>
            <div className="services-wrap mb-24">
              <div className="services-icon">
                <img src={iconTwo} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title dark-color">
                  <a href="#">SMS & Call Us</a>
                </h3>
                <p className="services-txt dark-color">(+48) 600 - 500 - 429</p>
              </div>
            </div>
            {/* <div className="services-wrap">
                            <div className="services-icon">
                                <img
                                    src={iconThree}
                                    alt="Choose Icon"
                                />
                            </div>
                            <div className="services-text">
                                <h3 className="title dark-color"><a href="#">Office Address</a></h3>
                                <p className="services-txt dark-color">Smolna 1D,<br></br>81-877 Sopot</p>
                            </div>
                        </div> */}
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="acznowy-contact mod1 zIndex1">
              <AppointmentForm
                formTitle="Schedule Appointment"
                formBtnClass="submit-btn orange-btn"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="line-inner orange-dark1">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  )
}

export default WhyChooseUs2
