import React from 'react'
import { Link } from 'react-router-dom'

import bgImg from '../../assets/img/bg/home-11.jpg'
import bgImg2 from '../../assets/img/bg/12.jpg'
import bgImg3 from '../../assets/img/bg/choose-bg.jpg'
import bgImg4 from '../../assets/img/bg/tech-bg.png'
import bgImg5 from '../../assets/img/bg/new-slider-bg.png'

import cksImg from '../../assets/img/choose/cks.png'
import ckaImg from '../../assets/img/choose/CKA.png'
import ckaCkdImg from '../../assets/img/choose/cka-ckd.png'
import azDec from '../../assets/img/choose/az-dec.png'

const bgStyle = {
  backgroundImage: `url(${bgImg4})`,
}

const BannerHomeEleven = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div
        className="acznowy-banner style7 modify3 acznowy-rain-animate"
        style={bgStyle}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content">
                <span className="sub-title zIndex1">Welcome to Acznowy</span>
                <h2
                  className="title zIndex1"
                  data-animation="fadeInLeft"
                  data-delay=".3s"
                >
                  Your idea, our responsibility. Hire a technology-agnostic
                  exclusive team.
                </h2>
                <p
                  className="desc zIndex1"
                  data-animation="fadeInLeft"
                  data-delay=".5s"
                >
                  Accelerate your project from Day 1, ensuring seamless cloud
                  migration, rapid deadlines, and great cost savings. We only
                  craft what make us proud.
                </p>
                <ul className="banner-btn zIndex1">
                  <li>
                    <Link to="/about" className="readon started ">
                      Get Started
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div id="certificates"  className="col-lg-6">
              <div className="row center">
                <div className="services-wrap">
                  <div className="services-img">
                    <img src={cksImg} alt="CKS Badge" />
                  </div>
                </div>
                <div className="services-wrap">
                  <div className="services-img">
                    <img
                      src={ckaImg}
                      alt="CKA Badge"
                    />
                  </div>
                </div>
                <div className="services-wrap">
                  <div className="services-img">
                    <img
                      src={azDec}
                      alt="Microsoft Certified Azure DevOps Engineer Expert Badge"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="line-inner style2 orange-dark">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  )
}

export default BannerHomeEleven
