import SectionTitle from '../../components/Common/SectionTitle'

// WhyChooseUs Images
import mainImg from '../../assets/img/choose/2.png'
import animImg1 from '../../assets/img/choose/dotted-1.png'
import animImg2 from '../../assets/img/choose/dotted-2.png'
import iconOne from '../../assets/img/choose/icons/1.png'
import iconTwo from '../../assets/img/choose/icons/2.png'
import iconThree from '../../assets/img/choose/icons/3.png'



const WhyChooseUs = () => {
  return (
    <div
      id="acznowy-why-choose-us"
      className="acznowy-why-choose-us style1 black-dark pt-120 pb-120 md-pt-70 md-pb-74"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 hidden-md">
            <div className="image-part">
              <img src={mainImg} alt="Main Image" />
            </div>

            <div className="animation style2 modify-style">
              <div className="top-shape">
                <img className="dance" src={animImg2} alt="Animation Two" />
              </div>
              <div className="bottom-shape">
                <img className="dance2" src={animImg1} alt="Animation One" />
              </div>
            </div>
          </div>

          <div className="col-lg-6 pl-60 md-pl-14">
            <SectionTitle
              sectionClass="sec-title mb-40"
              subtitleClass="sub-text white-color style4-bg"
              subtitle="Why Choose Us"
              titleClass="title white-color pb-20"
              title="Acznowy Delivers High-Performance Applications"
              descClass="desc white-color"
              description="Since 2020, Acznowy has been providing top-notch IT services, developing software applications and mobile apps for clients worldwide."
            />

            <div className="services-wrap mb-24">
              <div className="services-icon">
                <img src={iconOne} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title white-color">
                  <a href="#">Pioneering IT Solutions for Growth</a>
                </h3>
                <p className="services-txt white-color">
                  Acznowy expertly leverages Cloud, AI, and emerging
                  technologies to fuel growth and boost efficiency, keeping you
                  ahead in a competitive market.
                </p>
              </div>
            </div>
            <div className="services-wrap mb-24">
              <div className="services-icon">
                <img src={iconTwo} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title white-color">
                  <a href="#">Superior Quality and Performance</a>
                </h3>
                <p className="services-txt white-color">
                  Our certified professionals deliver top-notch, maintainable
                  code, ensuring optimal performance and reliability in DevOps,
                  MLOps, and beyond.
                </p>
              </div>
            </div>
            <div className="services-wrap">
              <div className="services-icon">
                <img src={iconThree} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title white-color">
                  <a href="#">Seamless Integration and Support</a>
                </h3>
                <p className="services-txt white-color">
                  Acznowy provides comprehensive documentation and support for
                  effortless integration and maintenance of your custom software
                  solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs
