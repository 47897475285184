import SectionTitle from '../../components/Common/SectionTitle'
import SingleServiceTwo from '../../components/Service/SingleServiceTwo'
import SingleServiceFive from '../../components/Service/SingleServiceFive'

// Service Icons
import imgMain5 from '../../assets/img/service/style3/main-img/5.png'
import imgHover5 from '../../assets/img/service/style3/hover-img/5.png'

import imgMain6 from '../../assets/img/service/style3/main-img/6.png'
import imgHover6 from '../../assets/img/service/style3/hover-img/6.png'

import imgMain7 from '../../assets/img/service/style3/main-img/7.png'
import imgHover7 from '../../assets/img/service/style3/hover-img/7.png'

import imgMain8 from '../../assets/img/service/style3/main-img/8.png'
import imgHover8 from '../../assets/img/service/style3/hover-img/8.png'

import imgMain9 from '../../assets/img/service/style3/main-img/9.png'
import imgHover9 from '../../assets/img/service/style3/hover-img/9.png'

import imgMain10 from '../../assets/img/service/style3/main-img/10.png'
import imgHover10 from '../../assets/img/service/style3/hover-img/10.png'

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png'
import hoverIcon1 from '../../assets/img/service/style2/hover-img/1.png'
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png'
import hoverIcon2 from '../../assets/img/service/style2/hover-img/2.png'
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png'
import hoverIcon3 from '../../assets/img/service/style2/hover-img/3.png'
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png'
import hoverIcon4 from '../../assets/img/service/style2/hover-img/4.png'
import mainIcon5 from '../../assets/img/service/style2/main-img/5.png'
import hoverIcon5 from '../../assets/img/service/style2/hover-img/5.png'
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png'
import hoverIcon6 from '../../assets/img/service/style2/hover-img/6.png'

const Service = () => {
  return (
    <div
      id="acznowy-service"
      className="acznowy-services style3 modify3 acznowy-rain-animate pt-120 pb-120 md-pt-70 md-pb-80"
    >
      <div className="container">
        <div className="row md-mb-60">
          <div className="col-lg-6 mb-60 md-mb-20">
            <SectionTitle
              sectionClass="sec-title2 zIndex1"
              subtitleClass="sub-text dark-color"
              subtitle="Top Software Development Services"
              titleClass="title testi-title dark-color"
              title="Comprehensive Solutions for Our Valued Clients"
            />
          </div>
          <div className="col-lg-6 mb-60 md-mb-0">
            <div className="btn-part text-right mt-60 md-mt-0 md-text-left">
              <a className="readon started " href="#">
                Get Started with Our Services
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-20">
            <SingleServiceFive
              itemClass="services-item green-bg"
              prefix="01"
              MainImg={imgMain5}
              HoverImg={imgHover5}
              Title="DevOps & DevSecOps"
              Text="Streamline your software development and delivery process with our DevOps and DevSecOps services. Boost collaboration, enhance security, and accelerate time-to-market by integrating development and operations teams."
              serviceBtn="Read More"
              btnClass="service-button color-primary"
              alt="image of DevOps & DevSecOps solutions"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-20">
            <SingleServiceFive
              itemClass="services-item paste-bg"
              prefix="02"
              MainImg={imgMain10}
              HoverImg={imgHover10}
              Title="Cloud & Kubernetes"
              Text="Experience seamless cloud migration and infrastructure management with our expert cloud engineering services. Optimize costs, improve scalability, and enhance business agility by leveraging cloud solutions like AWS, Azure, and Google Cloud."
              serviceBtn="Read More"
              btnClass="service-button color-primary"
              alt="image of Cloud Engineering & Kubernetes Engineering service"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-20">
            <SingleServiceFive
              itemClass="services-item purple-bg"
              prefix="03"
              MainImg={imgMain8}
              HoverImg={imgHover8}
              Title="Custom Software Development"
              Text="Develop custom software solutions tailored to your unique business needs. Our software development services cover web applications, mobile apps, and enterprise systems, helping you drive innovation and stay ahead of the competition."
              serviceBtn="Read More"
              btnClass="service-button color-primary"
              alt="image of Custom Software Development service"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-20">
            <SingleServiceFive
              itemClass="services-item orange-bg"
              prefix="04"
              MainImg={imgMain7}
              HoverImg={imgHover7}
              Title="MLOps & DataOps"
              Text="Accelerate your machine learning and data pipeline workflows with our MLOps and DataOps services. Ensure smooth collaboration, reduce errors, and improve the quality of your data-driven applications."
              serviceBtn="Read More"
              btnClass="service-button color-primary"
              alt="image of MLOps & DataOps solutions"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-20">
            <SingleServiceFive
              itemClass="services-item pink-bg"
              prefix="05"
              MainImg={imgMain6}
              HoverImg={imgHover6}
              Title="Data Science & AI"
              Text="Leverage the power of data and artificial intelligence to unlock insights and drive growth. Our data science and AI services help you harness machine learning, natural language processing, and predictive analytics for smarter decision-making."
              serviceBtn="Read More"
              btnClass="service-button color-primary"
              alt="image of Data Science & AI solutions"
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-20">
            <SingleServiceFive
              itemClass="services-item aqua-bg"
              prefix="06"
              MainImg={imgMain9}
              HoverImg={imgHover9}
              Title="Mobile & Web App Development"
              Text="Create responsive, modern websites and mobile apps to establish your online presence. Our web and mobile app development services focus on designing user-friendly, visually appealing solutions that showcase your company's products and services while improving search engine visibility."
              serviceBtn="Read More"
              btnClass="service-button color-primary"
              alt="image of Mobile & Web App Development service"
            />
          </div>
        </div>
      </div>
      <div className="line-inner orange-dark1">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  )
}

export default Service
